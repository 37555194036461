import './index.css';
import * as React from 'react';
import { useEffect, useRef } from 'react';

import { DocumentEditorContainerComponent, Editor, Selection, Toolbar, WordExport } from '@syncfusion/ej2-react-documenteditor';
import { TitleBar } from './title-bar';

DocumentEditorContainerComponent.Inject(Toolbar, Selection, Editor, WordExport);

const App = () => {
    useEffect(() => {
        rendereComplete();
    }, []);
    let hostUrl = "https://services.syncfusion.com/react/production/api/documenteditor/";
    let container = useRef(null);
    let titleBar;

    const onLoadDefault = () => {
        container.current.documentEditor.documentName = "Getting Started";
        titleBar.updateDocumentTitle();
        container.current.documentChange = () => {
            titleBar.updateDocumentTitle();
            container.current.documentEditor.focusIn();
        };
    };

    const rendereComplete = () => {
        window.onbeforeunload = function () {
            return "Want to save your changes?";
        };
        container.current.documentEditor.pageOutline = "#E0E0E0";
        container.current.documentEditor.acceptTab = true;
        container.current.documentEditor.resize();
        titleBar = new TitleBar(document.getElementById("documenteditor_titlebar"), container.current.documentEditor, true);
        onLoadDefault();
    };

    function createlinkDownload(blob) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'document.docx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    }

    const getBytesFromBlob = async (blob) => {
        try {
            const arrayBuffer = await blob.arrayBuffer();
            return new Uint8Array(arrayBuffer);
        } catch (error) {
            console.error('Error al obtener los bytes del Blob:', error);
        }
    }

    const showBytes = () => {
        // container.current.documentEditor.save('sample', 'Sfdt');
        // container.current.documentEditor.save('sample', 'Docx');
        container.current.documentEditor.saveAsBlob('Docx').then(async (exportedDocument) => {
            const bytes = await getBytesFromBlob(exportedDocument);
            console.log({ bytes })
        });
    }

    const downloadDocx = () => {
        container.current.documentEditor.saveAsBlob('Docx').then(async (exportedDocument) => {
            createlinkDownload(exportedDocument);
        });
    }

    return (
        <>
            <div className="control-pane">
                <div className="control-section">
                    <div id="documenteditor_titlebar" className="e-de-ctn-title"></div>
                    <div id="documenteditor_container_body">
                        <DocumentEditorContainerComponent
                            id="container"
                            ref={container}
                            style={{ display: "block" }}
                            height={"900px"}
                            serviceUrl={hostUrl}
                            enableToolbar={true}
                            locale="en-US"
                        />
                    </div>
                </div>
            </div>
            <button onClick={showBytes}>Mostrar Bytes</button>
            <button onClick={downloadDocx}>Descargar Docx</button>
        </>
    );
};

export default App;
